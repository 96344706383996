@import url("https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Overpass", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active {
  @apply bg-msp-yellow bg-opacity-40;
}

.link {
  @apply p-2.5 gap-6 md:cursor-pointer cursor-default duration-300 font-medium flex rounded-md items-center text-white hover:bg-msp-yellow hover:bg-opacity-40;
}
/* relative hover:bg-msp-yellow hover:bg-opacity-40   py-2 px-3 rounded-md cursor-pointer transition-colors  group text-white */
